<template>
  <el-card class="box-card">
    <span style="margin-left: 20px">模块开关:</span>
    <el-radio-group v-model="form.type" size="small" @change="submit">
      <el-radio-button :label="0">暂不启用</el-radio-button>
      <el-radio-button :label="1">启用</el-radio-button>
    </el-radio-group>
    <el-tabs v-model="tabs">
      <el-tab-pane label="砍价商品" name="tab_1">
        <vab-query-form-left-panel :span="24">
          <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加砍价</el-button>
          <!--          <el-button @click="bargainItemSettings" type="danger" size="small">砍价商品设置</el-button>-->
          <br/>
          <el-radio-group style="margin: 10px 0 5px 20px" v-model="queryForm.type" @change="buttonTab">
            <el-radio-button type="button"
                             v-for="item in button_state"
                             :key="item.id"
                             :label="item.id"
            >
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </vab-query-form-left-panel>

        <el-table
            :data="list"
            @selection-change="setSelectRows">
          <el-table-column
              align="center"
              prop="id"
              label="ID">
          </el-table-column>
          <el-table-column align="right" label="" show-overflow-tooltip>
            <template #default="{ row }">
              <el-image v-if="row.sku_info.imgUrl" :src="row.sku_info.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column align="left" label="商品" show-overflow-tooltip>
            <template #default="{ row }">
              <div>{{ row.sku_info.name }}</div>
            </template>
          </el-table-column>
          <!--          <el-table-column
                        align="center"
                        prop="price"
                        label="参与活动">
                    </el-table-column>-->
          <el-table-column
              align="center"
              prop="sku_info.price"
              label="商品价格">
          </el-table-column>
          <el-table-column
              align="center"
              prop="low_price"
              label="底价">
          </el-table-column>
          <el-table-column
              align="center"
              prop="participation_number"
              label="参与人数">
          </el-table-column>
          <el-table-column
              align="center"
              prop="succeed_number"
              label="砍价成功人数">
          </el-table-column>
          <el-table-column
              align="center"
              prop="inventory"
              label="砍价总库存">
          </el-table-column>
          <el-table-column
              align="center"
              label="活动时间"
              prop="create_at"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>开始时间:{{ scope.row.bargain.begin_time | formatDate }}</div>
              <div>结束时间:{{ scope.row.bargain.end_time | formatDate }}</div>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="bargain.state"
              :formatter="formatterState"
              label="状态">
          </el-table-column>
          <el-table-column align="center" label="操作" width="250">
            <template #default="{ row }">
              <el-button type="text" @click="handleInfo(row)">查看</el-button>
              <el-button type="text" @click="handleEdit(row)">修改</el-button>
              <el-button type="text" @click="bargainingList(row)">砍价列表</el-button>
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            :current-page="queryForm.page"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="count"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />
      </el-tab-pane>

      <el-tab-pane label="砍价列表" name="tab_2">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <i class="header-icon el-icon-search"></i> 搜索
            </template>
            <el-form :inline="true" v-model="queryForm_a" @submit.native.prevent>
              <el-form-item label="商品名称:">
                <el-input v-model="queryForm_a.goodsName" clearable size="small"/>
              </el-form-item>
              <el-form-item label="发起人名称:">
                <el-input v-model="queryForm_a.userName" clearable size="small"/>
              </el-form-item>
              <el-form-item label="砍价状态:">
                <el-select v-model="queryForm_a.state" clearable size="small">
                  <el-option label="失败" :value="0"></el-option>
                  <el-option label="进行中" :value="1"></el-option>
                  <el-option label="成功" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发起时间:">
                <el-date-picker
                    v-model="query_time"
                    size="small"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    @change="getTimeSection"
                />
              </el-form-item>
              <el-form-item>
                <el-button icon="el-icon-search" @click="queryData_a" type="primary" size="small">
                  查询
                </el-button>
                <el-button @click="resetForm('searchForm')" type="info" size="small">重置</el-button>
              </el-form-item>
            </el-form>
          </el-collapse-item>
        </el-collapse>
        <el-table v-loading="loading_a" :data="list_a">
          <el-table-column align="right" label="" show-overflow-tooltip>
            <template #default="{row}">
              <el-image v-if="row.user.pic" :src="row.user.pic" class="commodity_pic"/>
              <el-image v-else src="#" class="commodity_pic"/>
            </template>
          </el-table-column>
          <el-table-column
              align="left"
              prop="user.name"
              label="用户">
          </el-table-column>
          <el-table-column align="right" label="" show-overflow-tooltip>
            <template #default="{ row }">
              <el-image v-if="row.sku_info.imgUrl" :src="row.sku_info.imgUrl" class="commodity_pic"/>
              <el-image v-else src="#" class="commodity_pic"/>
            </template>
          </el-table-column>
          <el-table-column align="left" label="商品" show-overflow-tooltip>
            <template #default="{ row }">
              <div>{{ row.sku_info.name }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="发起砍价时间">
            <template slot-scope="scope">
              <div>{{ scope.row.create_at | formatDates }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="最少砍价数 / 已砍人数" show-overflow-tooltip width="200px">
            <template #default="{ row }">
              <div>{{ row.chop_number }} / {{ row.succeed_number }}</div>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="sku_info.price"
              label="底价">
          </el-table-column>
          <el-table-column
              align="center"
              prop="end_time"
              label="结束时间">
            <!--            <template slot-scope="scope">
                          <div>{{ scope.row.end_time | formatDate }}</div>
                        </template>-->
          </el-table-column>
          <el-table-column
              align="center"
              prop="state"
              label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 1">进行中</span>
              <span v-if="scope.row.state == 2">成功</span>
              <span v-else>失败</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template #default="{ row }">
              <el-button type="text" @click="helpChopRecords(row)">帮砍记录</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            :current-page="queryForm_a.page"
            :layout="layout_a"
            :page-size="queryForm_a.pageSize"
            :total="count_a"
            @current-change="handleCurrentChange_a"
            @size-change="handleSizeChange_a"
        />
      </el-tab-pane>
    </el-tabs>
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {
  apiGetBargainingGoodsList,
  apiGetBargainingList,
  apiDeleteBargainingGoods,
  apiGetGoodsConfig, apiUpdateGoodsConfig
} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
    formatDates(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      form: {type: ''},
      tabs: 'tab_1',
      // !!砍价商品
      button_state: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未开始'
        },
        {
          id: 2,
          name: '进行中'
        },
        {
          id: 3,
          name: '已结束'
        },
        {
          id: 4,
          name: '已关闭'
        }
      ],
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        type: 0,
      },
      list: [],
      // !!砍价商品

      // !!砍价列表
      activeName: '1',
      query_time: [],
      loading_a: false,
      layout_a: 'total, sizes, prev, pager, next, jumper',
      count_a: 0,
      queryForm_a: {
        page: 1,
        pageSize: 10,
        goodsName: '',
        userName: '',
        state: '',
        beginTime: '',
        endTime: '',
      },
      list_a: [],
      // !!砍价列表
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.fetchData_a()
    this.getGoodsConfig()
  },
  mounted() {
  },
  methods: {
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1
      } else if (num == 2) {
        this.queryForm.type = 2
      } else if (num == 3) {
        this.queryForm.type = 3
      } else if (num == 4) {
        this.queryForm.type = 4
      } else {
        this.queryForm.type = 0
      }
      this.fetchData()
    },
    /*bargainItemSettings() {
      this.$router.push({
        path: 'bargainItemSettings',
      })
    },*/
    //查看详情
    handleInfo(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      }
    },
    //跳转单个商品砍价列表
    bargainingList(row) {
      if (row.id) {
        this.$router.push({
          path: 'bargainingList',
          query: {
            id: row.id,
          },
        })
      }
    },
    //砍价商品跳转新增、修改页面
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'bargainGoods/revise',
          query: {
            id: row.bargain_id,
          },
        })
      } else {
        this.$router.push({
          path: 'bargainGoods/revise',
          query: {
            id: '',
          }
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteBargainingGoods({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetBargainingGoodsList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
    // 状态
    formatterState: function (row) {
      return row.bargain.state == '1' ? '未开始' : row.bargain.state == '2' ? '进行中' : row.bargain.state == '3' ? '已结束' : row.bargain.state == '4' ? '已关闭' : '暂无'
    },
    // !!砍价商品
    //获取模块开关配置
    getGoodsConfig() {
      apiGetGoodsConfig().then(res => {
        if (res.code == 200) {
          this.form.type = parseInt(res.data.value)
        }
      })
    },
    submit(event) {
      apiUpdateGoodsConfig({type: event}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.getGoodsConfig()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // !!砍价列表
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm_a.beginTime = event[0]
        this.queryForm_a.endTime = event[1]
      } else {
        this.queryForm_a.beginTime = ''
        this.queryForm_a.endTime = ''
      }
    },
    //重置
    resetForm(formName) {
      console.log(formName)
      this.queryForm_a = {
        page: 1,
        pageSize: 10,
        goodsName: '',
        userName: '',
        state: '',
        beginTime: '',
        endTime: '',
      }
      this.query_time = []
      this.fetchData_a()
    },
    //调整帮砍详情
    helpChopRecords(row) {
      if (row.id) {
        this.$router.push({
          path: 'helpChopRecords',
          query: {
            id: row.id,
          },
        })
      }
    },
    handleSizeChange_a(val) {
      this.queryForm.pageSize = val
      this.fetchData_a()
    },
    handleCurrentChange_a(val) {
      this.queryForm.page = val
      this.fetchData_a()
    },
    queryData_a() {
      this.queryForm.page = 1
      this.fetchData_a()
    },
    //砍价列表
    async fetchData_a() {
      this.loading_a = true
      apiGetBargainingList(this.queryForm_a).then(res => {
        if (res.code == 200) {
          this.list_a = res.data.list
          this.count_a = res.data.count
        }
      })
      this.loading_a = false
    },
  }
}

</script>
<style>
.tab_button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 9px 15px;
  font-size: 13px;
  border-radius: 0;
}

.tab_button1 {
  background: #409eff;
  color: #f0f0f0;
}

.commodity_pic {
  width: 60px;
  height: 60px;
}

.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
